import api from '../../axios'
export default {
    namespaced: true,
    state: {
        notificationMsg: [],
        notificationReaded: [],
        notificationUnread: [],
        lengthUnread: null,
        lengthRead: null
    },
    getters: {
        getNotificationMsg: (state) => state.notificationMsg
    },
    mutations: {
        SET_NOTIFICATION_MSG: (state, payload) => {
            state.notificationMsg = payload
        },
        SET_ALL_READ_NOTIFICATION_MSG: (state, payload) => {
            state.notificationReaded = payload
        },
        SET_ALL_UNREAD_NOTIFICATION_MSG: (state, payload) => {
            state.notificationUnread = payload
        }
    },
    actions: {
        async loadNotifications ({commit}) {
            await api.get('user/notifications')
                .then (res => {
                    commit('SET_NOTIFICATION_MSG', res.data)
                    // menghitung jumlah data notif yang blm di read
                    // const data = res.data.filter(x => x.read_at === null)
                })
                .catch (err => {
                    console.log(err)
                })

        },
        async loadAllReadNotifications ({commit}) {
            await api.get('user/all-read-notifications')
                .then (res => {
                    commit('SET_ALL_READ_NOTIFICATION_MSG', res.data)
                    // menghitung jumlah data notif yang blm di read
                    // const data = res.data.filter(x => x.read_at === null)
                })
                .catch (err => {
                    console.log(err)
                })

        },
        async loadAllUnreadNotifications ({commit}) {
            await api.get('user/all-unread-notifications')
                .then (res => {
                    commit('SET_ALL_UNREAD_NOTIFICATION_MSG', res.data)
                    // menghitung jumlah data notif yang blm di read
                    // const data = res.data.filter(x => x.read_at === null)
                })
                .catch (err => {
                    console.log(err)
                })

        },
        async markAllRead ({dispatch}) {
            await api.get('user/mark-all-notifications')
            .then (() => {
                dispatch('loadNotifications')
            })
            .catch (err => {
                console.log(err)
            })
        },
        async markRead ({dispatch}, payload) {
            const params = { id_notif: payload.id_notif }
            await api.get('user/read-notification', { params })
            .then (async () => {
                await dispatch('loadNotifications')
                document.location = payload.url_notif
            })
            .catch (err => {
                console.log(err)
            })
        }
    }
}
