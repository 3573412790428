/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'
import Echo from 'laravel-echo'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use (Vuesax)

// axios
import axios from './axios.js'
Vue.prototype.$http = axios

window.Pusher = require('pusher-js')
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    // key: 'e7d6356ffac0cc1d1c29',
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    // cluster: 'ap1',
    encrypted: false,
    authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
    forceTLS: true,
    auth: {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('bearerToken')}`
        }
    }
})

// mock
//import './fake-db/index.js'

// Theme Configurations
import '../themeConfig.js'

// Firebase
//import '@/firebase/firebaseConfig'

// ACL
import acl from './acl/acl'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// i18n
import i18n from './i18n/i18n'

// Vuexy Admin Filters
import './filters/filters'

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use (VueClipboard)

// Tour
import VueTour from 'vue-tour'
Vue.use (VueTour)
require ('vue-tour/dist/vue-tour.css')

// VeeValidate
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
extend('nullable', () => {
  return true
})
extend('numeric',  {
  message: field => `The ${field} may only contain numeric and can use decimals with '.' characters.`,
  validate: value => new RegExp('(?!^0*$)(?!^0*\\.0*$)^\\d{1,99}(\\.\\d{1,99})?$').test(value)
})


localize('en', en)

// Install components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use (VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'AIzaSyB4DDathvvwuwlwnUu7F4Sow3oU22y5T1Y',
    libraries: 'places' // This is required if you use the Auto complete plug-in
  }
})

// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer'
Vue.use (VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require ('./assets/css/iconfont.css')

Vue.config.productionTip = false
new Vue ({
  router,
  store,
  i18n,
  acl,
  render: h => h (App)
}).$mount ('#app')
