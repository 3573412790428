import api from '@/axios'
// import router from '../../router'
// import Swal from 'sweetalert2'

export default {
  namespaced: true,
  state: {
    dataDropdown: {}
  },
  getters: {
    getDataDropdown (state) {
      return state.dataDropdown
    }
  },
  mutations: {
    SET_DATA_DROPDOWN (state, payload) {
      state.currentDataDropdown = {
        id: '',
        name: 'All'
      }
      state.dataDropdown = payload
    }
  },
  actions: {
    async LOAD_DATA_DROPDOWN ({commit}) {
      await api.get ('master/classification/dropdown')
        .then ((rs) => {
          if (rs.status === 200) {
            commit ('SET_DATA_DROPDOWN', rs.data.data)
          }
        }).finally (() => {
        })
    }
  }

}
