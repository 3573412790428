import api from '../../../axios'

export default {
    namespaced: true,
    state: {
        chartShow : false,
        chartOptions: {
            labels: [],
            dataLabels: {
              enabled: true,
              textAnchor: 'middle'
            },
            legend: { show: false },
            chart: {
              type: 'pie',
              offsetY: 0,
              dropShadow: {
                enabled: false,
                blur: 5,
                left: 1,
                top: 1,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            stroke: {
              width: 5
            },
            colors: []  
        },
        summary: {
            analyticsData: [],
            series: [],
            total: null
        }
    },
    getters: {
        getChartOptions : (state) => state.chartOptions,
        getChartShow: (state) => state.chartShow,
        getSummary : (state) => state.summary
    },
    mutations: {
        SET_CHART_OPTIONS (state, payload) {
            state.chartOptions.labels = payload.labels
            state.chartOptions.colors = payload.colors
        },
        SET_SUMMARY (state, payload) {
            state.summary.analyticsData = payload.analyticsData
            state.summary.series = payload.series
            state.summary.total = payload.total
        },
        SET_CHART_SHOW (state, payload) {
            state.chartShow = payload
        }
    },
    actions: {
        load_summary ({commit}) {
            commit ('SET_CHART_SHOW', false)
            api.get ('summary')
            .then ((rs) => {
                if (rs.status === 200) {
                    const data = rs.data.data
                    const vesselType = data.type_vessel
                    const option = {
                        labels: vesselType.map(function (el) {
                            return el.name
                        }),
                        colors: vesselType.map(function (el) {
                            return el.color
                        })
                    }
                    const summary = {
                        analyticsData: vesselType.map(function (el) {
                            return {
                                color: el.color,
                                customerType: el.name
                            }
                        }),
                        series: vesselType.map(function (el) {
                            return el.vessel_count
                        }),
                        total: data.vessel.angka.total
                    }
                    commit ('SET_CHART_OPTIONS', option)
                    commit ('SET_SUMMARY', summary)
                    commit ('SET_CHART_SHOW', true)
                }
            })
            .catch(error => {
                console.log(error.data)
            })
            .finally (() => {

            })
        }
    }
    
}