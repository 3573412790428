/*
//
//  ______    _   _           _  __      _   _     ____   ___
// |  ____|  | | | |         | |/ _|    | | | |   |___ \ / _ \
// | |__ __ _| |_| |__   __ _| | |_ __ _| |_| |__   __) | | | |
// |  __/ _` | __| '_ \ / _` | |  _/ _` | __| '_ \ |__ <| | | |
// | | | (_| | |_| | | | (_| | | || (_| | |_| | | |___) | |_| |
// |_|  \__,_|\__|_| |_|\__,_|_|_| \__,_|\__|_| |_|____/ \___/
//
// Written by Fathalfath30.
// Email : fathalfath30@gmail.com
// Follow me on:
//  Github : https://github.com/fathalfath30
//  Gitlab : https://gitlab.com/Fathalfath30
//
// Licensed under GNU General Public License v3.0
// http://www.gnu.org/licenses/gpl-3.0.txt
// Vue Template Vuexy by Pixinvent
//
*/
import axios from 'axios'
import swalert from 'sweetalert2'
import storage_id from './storage_id'
import router from './router'

const x_axios = axios.create ({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem (storage_id.BEARER_TOKEN) || ''}`
  }
})

x_axios.interceptors.response.use ((response) => {
  return response
}, (error) => {

    const data = error.response.data
    if (error.response.config.url === 'auth/login') {
      // return Promise.reject (error)
      swalert.fire ({
        title: 'Oops...',
        icon: 'error',
        allowOutsideClick: false,
        text: error.response.data.message
      })
    } else if (error.response.status === 401) {
      swalert.fire ({
        icon: 'error',
        allowOutsideClick: false,
        html: `Sorry, it seems you session is expired. Please re-login<br/><br/>(Error code : ${data.code})`,
        onAfterClose () {
          localStorage.removeItem (storage_id.BEARER_TOKEN)
          localStorage.removeItem (storage_id.USER_INFO)
          router.push ({name: 'login-page'})
        }
      })
    }

  return Promise.reject (error)
})

export default x_axios
