import api from '../../axios'
import Swal from 'sweetalert2'
import i18n from '../../i18n/i18n'

export default {
  namespaced: true,
  state: {
    popupActive: false,
    detailData: Object
  },
  getters: {
    getPopoupActive : (state) => {
      return state.popupActive
    }
  },
  mutations: {
    SET_POPUP_ACTIVE (state, payload) {
      state.popupActive = payload
    },
    SET_DETAIL (state, payload) {
      state.detailData = payload
    }
  },
  actions: {
    LOAD_DETAIL ({commit}, payload) {
      const params = payload
      api.get('transaction/payment/license-vessel/detail', { params })
      .then(res => {
        let data = []
        data = res.data.data
        data.color = res.data.data.payment_status.id === '3' ? 'danger' : 'primary'
        commit('SET_DETAIL', data)
        return data
      })
      .catch(err => {
        console.log(err.response.data.message)
        return err
      })
    },
    CONFIRM_PAYMENT_LICENSE ({commit}, payload) {
      const params = payload
      return new Promise ((resolve, reject) => {
        api.patch ('transaction/payment/license-vessel/verified/done', params)
        .then ((rs) => {
          if (rs.status === 200) {
            Swal.fire ({
              title: i18n.t ('Success'),
              text: rs.data.message,
              icon: 'success'
            })
          }
          resolve()
        })
        .catch((err) => {
          Swal.fire ({
            title: i18n.t ('Failed'),
            text: err.response.data.message,
            icon: 'warning'
          })
          reject()
        })
      })
    },
    UPDATE_PAYMENT_LICENSE ({commit}, payload) {
      const params = payload
      return new Promise((resolve, reject) => {
        api.patch ('transaction/payment/license-vessel/verified/followup', params)
        .then ((rs) => {
          if (rs.status === 200) {
            Swal.fire ({
              title: i18n.t ('Success'),
              text: rs.data.message,
              icon: 'success'
            })
          }
          resolve()
        })
        .catch((err) => {
          Swal.fire ({
            title: i18n.t ('Failed'),
            text: err.response.data.message,
            icon: 'warning'
          })
          reject()
        })
      })
    },
    CANCEL_PAYMENT_LICENSE ({commit}, payload) {
      const params = payload
      api.patch ('transaction/payment/license-vessel/verified/cancelled', params)
      .then ((rs) => {
        if (rs.status === 200) {
          Swal.fire ({
            title: i18n.t ('Success'),
            text: rs.data.message,
            icon: 'success'
          })
        }
      })
      .catch((err) => {
        Swal.fire ({
          title: i18n.t ('Failed'),
          text: err.response.data.message,
          icon: 'warning'
        })
      })
    }
  }
}
