import api from '@/axios'
// import router from '../../router'
// import Swal from 'sweetalert2'

export default {
  namespaced: true,
  state: {
    dataDropdown: {}
  },
  getters: {
    getDataDropdown (state) {
      return state.dataDropdown
    }
  },
  mutations: {
    SET_DATA_DROPDOWN (state, payload) {
      state.dataDropdown = payload
    }
  },
  actions: {
    LOAD_DATA_DROPDOWN ({commit}) {
      api.get ('master/country/dropdown')
        .then ((rs) => {
          if (rs.status === 200) {
            commit ('SET_DATA_DROPDOWN', rs.data.data)
          }
        }).finally (() => {
      })
    }
  }

}
