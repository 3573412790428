/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use (Vuex)
import moduleAuth from './module/auth'
import moduleVessel from './module/vessel'
import moduleCompany from './module/company'
import moduleCompanyAdvertisement from './module/companyadvertisement'
import moduleAdsCategory from './module/adscategory'

import moduleVesselType from './module/vessel-type'
import moduleClassification from './module/classification'
import moduleCountry from './module/country'
import moduleRoles from './module/roles'

import modulePaymentLicense from './module/payment-license'

import moduleAnalyticVesselType from './module/analyticsData/vessel-type'
import moduleNotification from './module/notifications'

import moduleMaps from './module/maps'

export default new Vuex.Store ({
  strict: process.env.NODE_ENV !== 'production',
  state,
  getters,
  mutations,
  actions,
  modules: {

    // Auth
    moduleAuth,

    // Master
    moduleVessel,
    moduleCompany,
    moduleVesselType,
    moduleRoles,
    moduleClassification,
    moduleCountry,
    moduleCompanyAdvertisement,
    moduleAdsCategory,

    modulePaymentLicense,

    //Analytic Module
    moduleAnalyticVesselType,

    //Notification Module
    moduleNotification,

    // Maps Module
    moduleMaps
  }
})
