
module.exports = {
  // menu navigasi
  Master: 'Master',
  Transaction: 'Transaction',
  Menu: {
    MainMenu: 'Main Menu',
    MasterMenu: 'Master Menu',
    TransactionMenu: 'Transaction Menu',
    ReportingMenu: 'Report Menu',
    SettingMenu: 'Setting Menu',
    OtherMenu: 'Other Menu'
  },
  History: 'History',
  Welcome: {
    LoginPage: 'Welcome Back, please log in with your account.'
  },
  Message: {
    loginRequired: 'Invalid session. Please login again to access that page.',
    invalidData: 'The data is invalid.',
    areYouSure: 'Are you sure ?',
    deleteConfirmation: 'Data that has been deleted cannot be restored, would you like to do that?',
    acceptConfirmation: 'Data that has been approved cannot be changed, would you like to do that?',
    rejectConfirmation: 'Data that has been rejected cannot be changed, would you like to do that?',
    noData: '-No data available-',
    noDocument: 'No document was uploaded by the owner of this ship.',
    clickOnDocument: 'Click on the document to download or open the file below.',
    system: '-SYSTEM-',
    chooseOne: '-select one-',
    registeredVessel: 'These is a list of ships that have been registered in the system.',
    registeredVessel_valid: 'These is a list of vessels that have been registered in the system and have been validated.'
  },
  Validation: {
    currencyRequired: 'Currency is required',
    validate: 'Validation'
  },
  Pages: {
    MasterCountry: 'Master data Negara'
  },
  Information: {
    defaultPassword: 'The password will be generated by default by the system and will be sent to the email address.'
  },
  Status: {
    New: 'New',
    Rejected: 'Rejected',
    Verified: 'Verified',
    Pending: 'Pending',
    NotActive: 'NotActive',
    Active: 'Active'
  },
  PageTitle: 'This is {msg} data was registered',
  Password: 'Kata Sandi',
  Confirm: 'Konfirmasi',
  Cancel: 'Batal',
  Login: 'Masuk',
  ForgotPassword: 'Forgot Passoword',
  Dashboard: 'Dashboard',
  Email: 'Email',
  Country: 'Country',
  Id: 'Id',
  Name: 'Name',
  Iso2: 'ISO-2',
  Iso3: 'ISO-3',
  State: 'Province',
  City: 'City',
  Address: 'Address',
  Module: 'Module',
  QtyType: 'Quantity Type',
  Qty: 'Quantity',
  Company: 'Company',
  CompanyParent: 'Parent Company',
  Cargo: 'Cargo',
  CargoType: 'Cargo Type',
  CargoName: 'Cargo Name',
  CargoGroup: 'Cargo Group',
  CargoOwner: 'Cargo Owner',
  Ship: 'Ship',
  Port: 'Port',
  Roles: 'Role',
  UserModule: 'Module User',
  User: 'User',
  Users: 'Users',
  VesselType: 'Vessel Type',
  Classification: 'Classification',
  FuelType: 'Fuel Type',
  Vessel: 'Vessel',
  VesselDocument: 'Vessel Document',
  VesselName: 'Vessel Name',
  VerifyVessel: 'Vessel Validation',
  LicenseVessel: 'License Vessel',
  EngineHealth: 'Engine Health',
  CheckUpRequest: 'Request Status',
  License: 'License',
  Payments: 'Payments',
  PaymentLicense: 'Payment License',
  VerifyUser: 'User Verify',
  Shipments: 'Shipments',
  Offers: 'Offers',
  Offering: 'Offer',
  Setting: 'Setting',
  Logout: 'Logout',
  Create: 'Create',
  Update: 'Update',
  Detail: 'Detail',
  PhoneCode: 'Phone Code',
  Currency: 'Currency',
  Summary: 'Summary',
  InvalidData: 'Invalid Data',
  Success: 'Success!',
  Verified: 'Verified',
  NotVerified: 'Not Verified',
  ShipOwner: 'Ship Owner',
  Yes: 'Yes',
  No: 'No',
  Consignee: 'Consignee',
  Shipper: 'Shipper',
  Phone: 'Phone',
  RequireTugboat: 'Tugboat Required',
  CallSign: 'CallSign',
  IMONumber: 'IMO Number',
  ShipyardName: 'Shipyard',
  ClassificationDate: 'Classification Date',
  BuildYear: 'Build Year',
  LOA: 'LOA',
  LBP: 'LBP',
  Width: 'Width',
  Depth: 'Depth',
  SummerDraft: 'Summer Draft',
  WinterDraft: 'Winter Draft',
  TropicalDraft: 'Tropical Draft',
  FreshwaterDraft: 'Freshwater Draft',
  WeightGross: 'Weight Gross',
  WeightNet: 'Weight Net',
  Deadweight: 'Deadweight',
  MainEngine: 'Main Engine',
  AuxEngine: 'Aux Engine',
  EngineNumber: 'Engine Number',
  Valid: 'Valid',
  DefaultUser: 'Default User',
  AcceptRequest: 'Accept Request',
  RejectRequest: 'Reject Request',
  UpdateRequest: 'Update Request',
  Type: 'Type',
  Solid: 'Solid',
  Liquid: 'Liquid',
  VerifyCompany: 'Verify Company',
  UpdateVessel: 'Update Vessel',
  CreateVessel: 'Create Vessel',
  VesselCount: 'Vessel Count',
  PhoneNumber:'Phone Number',
  Role: 'Role',
  Username: 'Username',
  CustomField: 'Custom Field',
  LoadCapacity: 'Load Capacity',
  Owner: 'Owner',
  Rejected: 'Rejected',
  Deleted: 'Deleted',
  Advertisement: 'Advertisement',
  Branch: 'Branch',
  AdsCategory: 'Category Advertisement',
  AdsProduct: 'Product Advertisement',
  AdsProductLicense: 'License Product Advertisement',
  Report: 'Report',
  Log: {
    Main: 'Log',
    Visitor: 'Log Visitor',
    VisitorDetail: 'Visitor Detail',
    VisitorActivity: 'Visitor Activity',
    VisitorActivityDetail: 'Visitor Activity Detail'
  },
  Tracking: {
    Main: 'Tracking',
    TrackingAllPosition: 'All Position',
    TrackingFind: 'Find Vessel Position',
    TrackingDetail: 'Detail Vessel Position'
  },
  EmailTemplate: 'Email Template',
  OfferStatusType: 'Offer Status Type',
  ApiKeys: 'Api Key List',
  Settings: 'Settings',
}
