import api from '@/axios'
import router from '@/router'
import Swal from 'sweetalert2'

const vessel =  {
  namespaced: true,
  state: {
    selectedVessel: {},
    selectedVesselType: null,
    license: {},
    userCreate: {},
    formValidation: {},

    selectedParticulars: [],
    uploadPrecentage: 0,

    buildYear: [],
    isLoading: false
  },
  getters: {
    GET_SELECTED: state =>  { return state.selectedVessel },
    GET_PARTICULARS: state => { return state.selectedParticulars }
  },
  mutations: {
    SET_SELECTED (state, payload) {
      state.selectedVessel = {
        id: payload.id,
        name: payload.name,
        callsign: payload.callsign,
        imo: payload.imo_number,
        mmsi: payload.mmsi_number,
        stamp_name: payload.stamp,
        company: payload.company.id,
        shipyard: payload.shipyard_name,
        build_year: payload.build_year,
        country: payload.country.name,
        classification: payload.classification.name,
        vessel_type: payload.vessel_type.name,
        size_loa: payload.size.loa,
        size_lbp: payload.size.lbp,
        size_width: payload.size.width,
        size_depth: payload.size.depth,
        summer_draft: payload.draft.summer,
        winter_draft: payload.draft.winter,
        tropical_draft: payload.draft.tropical,
        freshwater_draft: payload.draft.freshwater,
        gross_tonnage: payload.weight.gross,
        net_tonnage: payload.weight.net,
        deadweight: payload.weight.deadweight,
        load_capacity: payload.load_capacity,
        main_engine: payload.engine.main.engine,
        main_engine_build_year: payload.engine.main.build_year,
        main_engine_number: payload.engine.main.number,
        horsepower: payload.engine.horsepower,
        fuel_type: payload.fuel_type.name,
        aux_engine: payload.engine.aux.engine,
        aux_engine_build_year: payload.engine.aux.build_year,
        aux_engine_number: payload.engine.aux.number,
        raztel_device_id: payload.raztel_device_id
      }
      state.selectedVesselType = payload.vessel_type.id
      state.license = payload.license
      state.license['is_valid'] = payload.is_valid
      state.license['notes'] = payload.notes
      state.userCreate = payload.user_create
      state.userCreate['created_at'] = payload.created_at
    },

    SET_PARTICULARS (state, payload) { state.selectedParticulars = payload },

    SET_FORM_VALIDATION (state, payload) {
      const data = payload.data
      const type = payload.type
      if (type === 'create') {
        state.formValidation = data
      } else {
        state.formValidation = data
        state.formValidation[Object.keys(state.formValidation)[0]].push({
          label:'Vessel Type',
          field:'vessel_type',
          mandatory: 'required'
        })
      }
    },

    SET_DETAIL_VALIDATION (state, payload) {
      state.formValidation = payload
    },

    // DELETE_SELECTED_PARTICULAR (state, payload) {
    //   state.selectedParticulars.splice(state.selectedParticulars.indexOf(payload), 1)
    // },

    COUNT_BUILD_YEAR (state) {
      const yearNow = new Date().getFullYear()
      let minYear = yearNow - 50
      const maxYear = yearNow
      while (minYear <= maxYear) {
        state.buildYear.push(minYear)
        minYear++
      }
    },

    SET_LOADING (state, payload) { state.isLoading = payload },

    RESET_FORM (state) {
      state.selectedVessel = {
        id: '',
        name: '',
        callsign: '',
        imo: '',
        stamp_name: '',
        company: '',
        shipyard: '',
        build_year: '',
        country: '',
        classification: '',
        vessel_type: '',
        size_loa: '',
        size_lbp: '',
        size_width: '',
        size_depth: '',
        summer_draft: '',
        winter_draft: '',
        tropical_draft: '',
        freshwater_draft: '',
        gross_tonnage: '',
        net_tonnage: '',
        deadweight: '',
        load_capacity: '',
        main_engine: '',
        main_engine_build_year: '',
        main_engine_number: '',
        horsepower: '',
        fuel_type: '',
        aux_engine: '',
        aux_engine_build_year: '',
        aux_engine_number: ''
      }
      state.selectedParticulars = []
      state.formValidation = {}
    },

    SET_UPLOAD_PERCENTAGE (state, payload) {
      state.uploadPrecentage = payload
    }
  },
  actions: {
    async LOAD_SELECTED ({commit}, payload) {
      const paramsOne = {id: payload}
      commit('SET_LOADING', true)
      commit('RESET_FORM')

      await api.get('master/vessel/detail', { params: paramsOne })
      .then((res) => {
        const data = res.data.data
        commit('SET_SELECTED', data)
      })
      .catch((err) => {
        commit('SET_SELECTED', Object)
        console.log(err)
      })

      commit('SET_LOADING', false)
    },
    async LOAD_FORM_VALIDATION ({commit}, payload) {
      const params = {
        id: payload.id
      }
      await api.get('master/vessel-type/detail-validation', { params })
        .then(res => {
          const data = {
            data: res.data.data.data_validate,
            type: payload.type
          }

          commit('SET_FORM_VALIDATION', data)

        })
        .catch(err => {
          console.log(err.response.data.message)
        })
        .then(() => {

        })
    },

    async UPDATE_SELECTED ({commit, dispatch}, payload) {
      const params = payload
      commit('SET_LOADING', true)
      await api.patch('master/vessel/update', params)
      .then((res) => {
        Swal.fire({
          title: 'Good Job!',
          text: res.data.message,
          icon: 'success',
          confirmButtonText: 'Oke'
        })
        commit('RESET_FORM')
        commit('SET_SHOW_FORM_UPDATE')
        dispatch('LOAD_SELECTED', params.id)
      })
      .catch((err) => {
        console.log('UPDATE Vessel', err.response.data.message)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })

    },
    async UPLOAD_PARTICULARS ({commit}, payload) {
      const data = payload.files
      const formFiles = new FormData()
      for (let i = 0; i < data.length; i++) {
        formFiles.append('id', payload.id)
        formFiles.append(`files[${i}]`, data[i])
      }
      console.log(formFiles)
      await api.post('master/vessel/doc/upload', formFiles,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            //FUNGSI INI YANG MEMILIKI PERAN UNTUK MENGUBAH SEBERAPA JAUH PROGRESS UPLOAD FILE BERJALAN
            onUploadProgress: function (progressEvent) {
              //DATA TERSEBUT AKAN DI ASSIGN KE VARIABLE progressBar
              commit('SET_UPLOAD_PERCENTAGE', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)))
            }.bind(this)
          })
        .then(() => {
          Swal.fire({
            title: 'Good Job!',
            text: 'Anda berhasil menambahkan vessel baru',
            icon: 'success',
            allowOutsideClick: false,
            confirmButtonText: 'Oke',
            onAfterClose () {
              router.push({
                name: 'detail-master-vessel',
                params: {
                  id: payload.id
                }
              })
            }
          })
        })
        .catch((err) => {
          Swal.fire({
            title: 'Oops...',
            text: err.response.data.message,
            icon: 'info',
            allowOutsideClick: false,
            confirmButtonText: 'Oke'
          })
        }).then(() => {
          commit('SET_UPLOAD_PERCENTAGE', 0)
        })
    },
    async LOAD_PARTICULARS ({commit}, payload) {
      const params = {vessel: payload}
      api.get('master/vessel/doc', { params })
        .then((res) => {
          const data = res.data.data
          commit('SET_PARTICULARS', data)
        })
    },
    async DELETE_PARTICULAR ({dispatch}, payload) {
      const params = payload
      await api.delete('master/vessel/doc/delete', {params})
        .then(res => {
          Swal.fire({
            title: 'Good Job!',
            text: res.data.message,
            icon: 'success',
            allowOutsideClick: false,
            confirmButtonText: 'Oke',
            onAfterClose () {
              dispatch('LOAD_PARTICULARS', payload.vessel)
            }
          })
        })
        .catch(err => {
          Swal.fire({
            title: 'Oops...',
            text: err.response.data.message,
            icon: 'error',
            allowOutsideClick: false,
            confirmButtonText: 'Oke'
          })
        })

    },

    async REJECT_VESSEL ({commit}, payload) {
      commit('SET_LOADING', true)
      api.patch ('master/vessel/validation/reject', payload)
        .then ((rs) => {
          Swal.fire ({
            title: 'Good Job!',
            text: rs.data.message,
            icon: 'success',
            onAfterClose () {
              router.push({
                name: 'verify-master-vessel'
              })
            }
          })
        })
        .catch(() => {

        })
        .then(() => {
          commit('SET_LOADING', false)
        })
    },
    async ACCEPT_VESSEL ({commit}, payload) {
      // const vm = this
      commit('SET_LOADING', true)

      const id = payload.id
      const loader = payload.loader

      loader.loading()

      api.patch ('master/vessel/validation/validate', { id: id })
          .then ((rs) => {
            Swal.fire ({
              title: 'Good Job!',
              text: rs.data.message,
              icon: 'success',
              onAfterClose () {
                router.push({
                  name: 'verify-master-vessel'
                })
              }
            })
          })
          .catch(() => {

          })
          .then(() => {
            commit('SET_LOADING', false)
            loader.loading.close()
          })
    },
    async ADD_VESSEL ({dispatch}, payload) {
      const data = payload.vessel
      api.post('master/vessel/create-admin-custom', data)
        .then(async (res) => {

          const meta = res.data.data
          await dispatch('UPLOAD_PARTICULARS', {
            id: meta.vessel,
            files: payload.files
          })
        })
        .catch((err) => {
          Swal.fire({
            title: 'Oops!',
            text: err.response.data.message,
            icon: 'error',
            confirmButtonText: 'Oke'
          })
        })
    }
    // async LOAD_VESSELS () {
    //   let params = {
    //     'draw': '0',
    //     // 'order[0][column]': '',
    //     // 'order[0][dir]': 'desc',
    //     // 'search[value]': '',
    //     // 'search[regex]': 'false',
    //     'start': '0',
    //     'length': '5',
    //     'vessel_type': '2',
    //     'tugboat': 'F',
    //     'range_min': '10',
    //     'range_max': '100',

    //     'columns[0][data]': '0',
    //     'columns[0][name]': 'id',
    //     'columns[0][searchable]':'true',
    //     'columns[0][orderable]':'true',
    //     'columns[0][search][value]': '',
    //     'columns[0][search][regex]': 'false',
    //     'columns[1][data]': '1',
    //     'columns[1][name]': 'name',
    //     'columns[1][searchable]': 'true',
    //     'columns[1][orderable]': 'true',
    //     'columns[1][search][value]': '',
    //     'columns[1][search][regex]': 'false',

    //     'columns[2][data]': '2',
    //     'columns[2][name]': 'load_capacity',
    //     'columns[2][searchable]': 'true',
    //     'columns[2][orderable]': 'true',
    //     'columns[2][search][value]': '',
    //     'columns[2][search][regex]': 'false',

    //     'columns[3][data]': '3',
    //     'columns[3][name]': 'created_at',
    //     'columns[3][searchable]': 'true',
    //     'columns[3][orderable]': 'true',
    //     'columns[3][search][value]': '',
    //     'columns[3][search][regex]': 'false',

    //     'columns[4][data]': '4',
    //     'columns[4][name]': 'classification',
    //     'columns[4][searchable]': 'true',
    //     'columns[4][orderable]': 'true',
    //     'columns[4][search][value]': '',
    //     'columns[4][search][regex]': 'false',

    //     'columns[5][data]': '5',
    //     'columns[5][name]': 'vessel_type',
    //     'columns[5][searchable]': 'true',
    //     'columns[5][orderable]': 'true',
    //     'columns[5][search][value]': '',
    //     'columns[5][search][regex]': 'false',

    //     'columns[6][data]': '6',
    //     'columns[6][name]': 'require_tugboat',
    //     'columns[6][searchable]': 'true',
    //     'columns[6][orderable]': 'true',
    //     'columns[6][search][value]': '',
    //     'columns[6][search][regex]': 'false',

    //     'columns[7][data]': '7',
    //     'columns[7][name]': 'valid',
    //     'columns[7][searchable]': 'true',
    //     'columns[7][orderable]': 'true',
    //     'columns[7][search][value]': '',
    //     'columns[7][search][regex]': 'false',

    //     'columns[8][data]': '8',
    //     'columns[8][name]': 'user_create',
    //     'columns[8][searchable]': 'true',
    //     'columns[8][orderable]': 'true',
    //     'columns[8][search][value]': '',
    //     'columns[8][search][regex]': 'false'
    //   }
    //   await api ()
    //   .get('master/vessel', {params: params})
    //   .then((res) => {
    //     let data = res.data.data
    //     console.log(data)
    //   })
    //   .catch((err) => {
    //     console.log(err.response.data.message)
    //   })
    // }
  }
}

export default vessel
