import API from '@/axios'

export default {
  namespaced: true,
  state: {
    dataDropdown: {},
    detailCompany: {},
    detailBranch: {
        location: {
            latitude : {
                decimal: '',
                dms:[]
            },
            longitude: {
                decimal: '',
                dms:[]
            }
        }
    }
  },
  getters: {
    getDataDropdown (state) {
      return state.dataDropdown
    }
  },
  mutations: {
    SET_DATA_DROPDOWN (state, payload) {
      state.dataDropdown = payload
    },
    SET_DETAIL_COMPANY (state, payload) {
        state.detailCompany = payload
    },
    SET_DETAIL_BRANCH (state, payload) {
        state.detailBranch = payload
    }
  },
  actions: {
    LOAD_DATA_DROPDOWN ({commit}, payload) {
      const params = payload
      API.get ('publicity/company/dropdown', { params })
        .then ((rs) => {
            commit ('SET_DATA_DROPDOWN', rs.data.data)
        })
    },
    UPLOAD_LOGO ({commit}, payload) {
        const file = payload.logo
        const formFile = new FormData()
        file.forEach(element => {
            formFile.append('id', payload.id)
            formFile.append('logo', element)
        })
        return new Promise ((resolve, reject) => {
            API.post('publicity/company/upload', formFile, {header: {'Content-Type': 'multipart/form-data'}})
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    LOAD_DETAIL_COMPANY ({commit}, payload) {
        const params = {id: payload}
        return new Promise ((resolve, reject) => {
            API.get('publicity/company/detail', {params})
                .then((res) => {
                    commit ('SET_DETAIL_COMPANY', res.data.data)
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    LOAD_DETAIL_BRANCH ({commit}, payload) {
        const params = {id: payload}
        return new Promise ((resolve, reject) => {
            API.get('publicity/companybranch/detail', {params})
                .then((res) => {
                    commit ('SET_DETAIL_BRANCH', res.data.data)
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    CREATE_DATA_COMPANY ({commit}, payload) {
        const file = payload.logo
        const formFile = new FormData()
        file.forEach(element => {
            formFile.append('name', payload.name)
            formFile.append('logo', element)
            formFile.append('website', payload.website)
            formFile.append('notes', payload.notes)
        })
        
        return new Promise ((resolve, reject) => {
            API.post('publicity/company/create', formFile, {header: {'Content-Type': 'multipart/form-data'}})
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    CREATE_DATA_BRANCH ({commit}, payload) {
        const params = payload
        return new Promise ((resolve, reject) => {
            API.post('publicity/companybranch/create', params)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    UPDATE_DATA_COMPANY ({commit}, payload) {
        const params = payload
        return new Promise ((resolve, reject) => {
            API.patch('publicity/company/update', params)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    UPDATE_DATA_BRANCH ({commit}, payload) {
        const params = payload
        return new Promise ((resolve, reject) => {
            API.patch('publicity/companybranch/update', params)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    DELETE_DATA_COMPANY ({commit}, payload) {
        const params = {id: payload}
        return new Promise ((resolve, reject) => {
            API.delete('publicity/company/delete', {params})
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    DELETE_DATA_BRANCH ({commit}, payload) {
        const params = {id: payload}
        return new Promise ((resolve, reject) => {
            API.delete('publicity/companybranch/delete', {params})
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
  }

}
