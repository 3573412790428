/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '../layouts/components/navbar/navbarSearchAndPinList'
import themeConfig, {colors} from '../../themeConfig.js'
import storage_id from '../storage_id'

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getUserInfo = () => {
  try {
    const local = JSON.parse (localStorage.getItem (storage_id.USER_INFO)) || null
    return {
      uid: local.UserId,
      displayName: local.Username,
      fullName: local.FullName,
      email: local.Email,
      about: local.Role.Name.toLowerCase,
      photoURL: ((local.DisplayPicture || null) === null) ? require ('../assets/images/default.png') : local.DisplayPicture,
      status: 'online',
      userRole: local.Role.Name.toLowerCase ()
    }

  } catch (e) {
    localStorage.removeItem (storage_id.USER_INFO)
    localStorage.removeItem (storage_id.BEARER_TOKEN)
  }
}

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split (' ')
  const mq = function (query) {
    return window.matchMedia (query).matches
  }

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join ('touch-enabled),('), 'heartz', ')'].join ('')
  return mq (query)
}

const state = {
  AppActiveUser: getUserInfo (),
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  is_touch_device: is_touch_device (),
  mainLayoutType: themeConfig.mainLayoutType || 'vertical',
  navbarSearchAndPinList,
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: 'default',
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  starredPages: navbarSearchAndPinList['pages'].data.filter ((page) => page.is_bookmarked),
  theme: themeConfig.theme || 'light',
  themePrimaryColor: colors.primary,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null
}

export default state
