/*
//
//  ______    _   _           _  __      _   _     ____   ___
// |  ____|  | | | |         | |/ _|    | | | |   |___ \ / _ \
// | |__ __ _| |_| |__   __ _| | |_ __ _| |_| |__   __) | | | |
// |  __/ _` | __| '_ \ / _` | |  _/ _` | __| '_ \ |__ <| | | |
// | | | (_| | |_| | | | (_| | | || (_| | |_| | | |___) | |_| |
// |_|  \__,_|\__|_| |_|\__,_|_|_| \__,_|\__|_| |_|____/ \___/
//
// Written by Fathalfath30.
// Email : fathalfath30@gmail.com
// Follow me on:
//  Github : https://github.com/fathalfath30
//  Gitlab : https://gitlab.com/Fathalfath30
//
// Licensed under GNU General Public License v3.0
// http://www.gnu.org/licenses/gpl-3.0.txt
//
*/
import storage_id from '../../storage_id'
import api from '../../axios'
import router from '../../router'
import Swal from 'sweetalert2'

export default {
  namespaced: true,
  state: {
    UserInfo: (() => {
      try {
        return JSON.parse (localStorage.getItem (storage_id.USER_INFO)) || null
      } catch (e) {
        return null
      }
    }) (),
    bearerToken: localStorage.getItem (storage_id.BEARER_TOKEN) || null,
    loginAlert: (() => {
      const alert = ((localStorage.getItem (storage_id.LOGIN_REQUIRED) || '') === 'T')
      if (alert) {
        localStorage.removeItem (storage_id.LOGIN_REQUIRED)
        return true
      }

      return false
    }) ()
  },
  mutations: {
    SET_USER_INFO (state, payload) {
      if (payload === null) {
        state.UserInfo = null
        localStorage.removeItem (storage_id.BEARER_TOKEN)
        localStorage.removeItem (storage_id.BEARER_TOKEN)

        return
      }

      localStorage.setItem (storage_id.BEARER_TOKEN, payload.token.access_token)
      localStorage.setItem (storage_id.USER_INFO, JSON.stringify ({
        UserId: payload.data.user_id,
        Email: payload.data.email,
        Username: payload.data.username,
        FullName: payload.data.full_name,
        DisplayPicture: payload.data.picture,
        Role: {
          Id: payload.data.roles.id,
          Name: payload.data.roles.name
        },
        Company: {
          Id: payload.data.company.id,
          Name: payload.data.company.name
        }
      }))
    }
  },
  actions: {
    login ({commit}, payload) {
      api.post ('auth/login', {
        user: payload.data.user,
        password: payload.data.password,
        login_type: 'AD'
      }).then ((rs) => {
        if (rs.status === 200) {
          commit ('SET_USER_INFO', rs.data.data)
          // router.push ({name: 'dashboard'}).catch(() => {})
          document.location = '/dashboard'
        }
      }).finally (() => {
        payload.closeAnimation ()
      })
    },

    forgotpassword ({commit}, payload) {
      api.post ('auth/forgotpassword', {
        email: payload.data.email
      }).then ((rs) => {
        if (rs.status === 200) {
          Swal.fire({
            title: 'Berhasil',
            text: `Verifikasi telah dikirim ke ${payload.data.email}`,
            icon: 'success'
          })
        }
      }).catch(err => {
        let errMessage = 'Error: Gagal melakukan lupa password!'

        const res = err.response
        if(typeof res.data !== 'undefined' && res.data !== null){
          if(typeof res.data.message !== 'undefined' && res.data.message !== null){
            errMessage = res.data.message
          }
        }

        Swal.fire({
          title: 'Gagal',
          text: errMessage,
          icon: 'error'
        })
      }).finally (() => {
        payload.closeAnimation ()
      })
    },

    logout ({commit}) {
      api.post('auth/logout')
        .then(() => {
          commit ('SET_USER_INFO', null)
          localStorage.clear()
          router.push ({path: '/auth/login'})
        })
        .catch(() => {
          localStorage.clear()
          router.push ({path: '/auth/login'})
        })
    }
  },
  getters: {
    isLoggedIn: state => !!state.bearerToken,
    getUserInfo (state) {
      return state.UserInfo
    }
  }
}
