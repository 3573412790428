import api from '../../axios'
// import router from '../../router'
// import Swal from 'sweetalert2'

export default {
    namespaced: true,
    state: {
      dataDropdown: {},
      currentData: {
          id: '',
          name: 'All'
      }
    },
    getters: {
        getDataDropdown (state) {
            return state.dataDropdown
        },
        getData (state) {
            return state.currentData
        }
    },
    mutations: {
      SET_DATA_DROPDOWN (state, payload) {
        state.currentData = {
          id: '',
          name: 'All'
        }
        state.dataDropdown = payload
      },
      SET_ID (state, payload) {
        
        if (payload === null) {
            state.currentData = {
                id: '',
                name: 'All'
            }
        } else {
            state.currentData = state.dataDropdown.find(obj => obj.id === payload)
        }
      }
    },
    actions: {
      load_roles_dropdown ({commit}) {
        api.get ('master/roles/dropdown')
        .then ((rs) => {
          if (rs.status === 200) {
            commit ('SET_DATA_DROPDOWN', rs.data.data)
          }
        }).finally (() => {
        })
      }
    }
    
  }
