import API from '@/axios'

export default {
  namespaced: true,
  state: {
    dataDropdown: {},
    dataCategory: [],
    detailCategory: {}
  },
  getters: {
    getDataDropdown (state) {
      return state.dataDropdown
    }
  },
  mutations: {
    SET_DATA_DROPDOWN (state, payload) {
      state.dataDropdown = payload
    },
    SET_DETAIL_CATEGORY (state, payload) {
        state.detailCategory = payload
    }
  },
  actions: {
    LOAD_DATA_DROPDOWN ({commit}, payload) {
      const params = payload
      api.get ('publicity/category/dropdown', { params })
        .then ((rs) => {
            commit ('SET_DATA_DROPDOWN', rs.data.data)
        })
    },
    LOAD_DETAIL_CATEGORY ({commit}, payload) {
        const params = {id: payload}
        return new Promise ((resolve, reject) => {
            API.get('publicity/category/detail', {params})
                .then((res) => {
                    commit ('SET_DETAIL_CATEGORY', res.data.data)
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    CREATE_DATA_CATEGORY ({commit}, payload) {
        const file = payload.icon
        const formFile = new FormData()
        file.forEach(element => {
            formFile.append('name', payload.name)
            formFile.append('icon', element)
            formFile.append('notes', payload.notes)
        })
        
        return new Promise ((resolve, reject) => {
            API.post('publicity/category/create', formFile, {header: {'Content-Type': 'multipart/form-data'}})
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    UPLOAD_ICON ({commit}, payload) {
        const file = payload.icon
        const formFile = new FormData()
        file.forEach(element => {
            formFile.append('id', payload.id)
            formFile.append('icon', element)
        })
        return new Promise ((resolve, reject) => {
            API.post('publicity/category/upload', formFile, {header: {'Content-Type': 'multipart/form-data'}})
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    UPDATE_DATA_CATEGORY ({commit}, payload) {
        const params = payload
        return new Promise ((resolve, reject) => {
            API.patch('publicity/category/update', params)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    DELETE_DATA_CATEGORY ({commit}, payload) {
        const params = {id: payload}
        return new Promise ((resolve, reject) => {
            API.delete('publicity/category/delete', {params})
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
  }

}
