import Vue from 'vue'
import {AclCreate, AclInstaller, AclRule} from 'vue-acl'
import router from '../router'
import storage_id from '../storage_id'

Vue.use (AclInstaller)
let initialRole = 'guest'

try {
  const userInfo = JSON.parse (localStorage.getItem (storage_id.USER_INFO)) || null
  if (userInfo && userInfo.Role.Name) {
    initialRole = userInfo.Role.Name.toLowerCase ()
  }
} catch (e) {
  initialRole = 'guest'

  localStorage.removeItem (storage_id.USER_INFO)
  localStorage.removeItem (storage_id.BEARER_TOKEN)
}

export default new AclCreate ({
  initial: initialRole,
  notfound: '/error401',
  router,
  acceptLocalRules: true,
  globalRules: {
    administrator: new AclRule ('administrator').generate (),
    all: new AclRule ('guest')
        .or ('administrator')
        .generate ()
  }
})
