import mutator from './../../utils/mutator'

const baseConfigMap = {
  currentZoom: 0,
  currentCenter: [
    0,
    0
  ],
  currentBounds: [
    [14.43468021529728, 141.06445312500003],
    [-17.602139123350838, 77.78320312500001]
  ],
  paddingTopLeft: [150, 100], // [left, top]
  paddingBottomRight: [250, 50], // [right, bottom]
  polyline: {
    show: false,
    latlngs: [],
    color: '#1c344f',
    dash: "4"
  },
  destinationMarker: {
    data: {},
    latlngs: [],
    show: false,
    type: ''
  },
  fromMarker: {
    data: {},
    latlngs: [],
    show: false,
    type: ''
  },
  mapOptions: {
    zoomSnap: 0.25,
    zoomDelta: 0.25,
    zoomControl: false
  },
  tileProviders: [
    {
      name: 'Default',
      visible: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    },
    {
      name: 'MapBox',
      visible: true,
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmF0aGFsZmF0aDMwIiwiYSI6ImNrM3NlcXB5NTA1cGszbW80ZDJnNTV3azEifQ.b_7rn9WvpIqbhXAuYZQlYw',
      attribution:'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    }
  ],
  clusterOptions: {
    disableClusteringAtZoom: 18,
    chunkedLoading: true
  },
  vesselEntries: [],
  vesselDetail: {},
  isPopupActive: false
}

const maps = {
  namespaced: true,
  state: {
    ...baseConfigMap
  },
  getters: {
    //
  },
  mutations: {
    SET_STATE: mutator,

    RESET_STATE (state) {
      Object.assign(state, {
        ...baseConfigMap
      })
    }
  },
  actions: {}
}

export default maps
