import api from '@/axios'
// import router from '../../router'
// import Swal from 'sweetalert2'

export default {
  namespaced: true,
  state: {
    dataDropdown: {},
    currentDataDropdown: {
      id: '',
      name: 'All'
    }
  },
  getters: {
    getDataDropdown (state) {
      return state.dataDropdown
    },
    getCurrentDataDropdown (state) {
      return state.currentDataDropdown
    }
  },
  mutations: {
    SET_DATA_DROPDOWN (state, payload) {
      state.currentDataDropdown = {
        id: '',
        name: 'All'
      }
      state.dataDropdown = payload
    },
    SET_ID_DROPDOWN (state, payload) {
      if (payload === null) {
        state.currentDataDropdown = {
          id: '',
          name: 'All'
        }
      } else {
        state.currentDataDropdown = state.dataDropdown.find(obj => obj.id === payload)
      }
    }
  },
  actions: {
    async LOAD_DATA_DROPDOWN ({commit}) {
      await api.get ('master/vessel-type/dropdown')
        .then ((rs) => {
          if (rs.status === 200) {
            commit ('SET_DATA_DROPDOWN', rs.data.data)
          }
        }).finally (() => {
      })
    }

  }

}
