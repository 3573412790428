import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'

Vue.use (Router)
const router = new Router ({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          redirect: '/dashboard',
          meta: {
            rule: 'administrator',
            loginRequired: true
          }
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('./views/Dashboard.vue'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Dashboard',
            breadcrumb: [{title: 'Home', url: '/', active: true}]
          }
        },
        // ///////////////////////////////////////////////////////////
        // master: country
        // ///////////////////////////////////////////////////////////
        {
          path: 'master/country',
          name: 'master-country',
          component: () => import ('./views/pages/country/Read'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Country',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Country', active: true}
            ]
          }
        },
        {
          path: 'master/country/detail/:id',
          name: 'detail-master-country',
          component: () => import ('./views/pages/country/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Detail',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Country', url: '/master/country'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: 'master/country/create',
          name: 'create-master-country',
          component: () => import ('./views/pages/country/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Create',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Country', url: '/master/country'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: 'master/country/update',
          name: 'update-master-country',
          component: () => import ('./views/pages/country/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Update',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Country', url: '/master/country'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master: report
        // ///////////////////////////////////////////////////////////
        {
          path: 'master/report',
          name: 'master-report',
          component: () => import ('./views/pages/report/Read'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Report',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Report', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master: log
        // ///////////////////////////////////////////////////////////
        {
          path: 'master/log/visitor',
          name: 'master-log-visitor',
          component: () => import ('./views/pages/log/visitor/Read'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Log.Visitor',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Log.Visitor', active: true}
            ]
          }
        },

        {
          path: 'master/log/visitor/detail/:id',
          name: 'master-log-visitor-detail',
          component: () => import ('./views/pages/log/visitor/ReadDetail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Log.VisitorActivityDetail',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Log.Visitor', url: '/master/log/visitor'},
              {title: 'Log.VisitorActivityDetail', active: true}
            ]
          }
        },

        {
          path: 'master/log/visitor/activity/:id',
          name: 'master-log-visitor-activity',
          component: () => import ('./views/pages/log/visitor/ReadActivity'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Log.VisitorActivity',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Log.Visitor', url: '/master/log/visitor'},
              {title: 'Log.VisitorActivity', active: true}
            ]
          }
        },

        {
          path: 'master/log/visitor/activity/:id/detail/:visitDate/:pkey',
          name: 'master-log-visitor-activity-detail',
          component: () => import ('./views/pages/log/visitor/ReadActivityDetail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Log.VisitorActivityDetail',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Log.Visitor', url: '/master/log/visitor'},
              {title: 'Log.VisitorActivityDetail', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master: tracking
        // ///////////////////////////////////////////////////////////
        {
          path: 'master/tracking',
          name: 'master-tracking',
          component: () => import ('./views/pages/tracking/AllPosition'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Tracking.TrackingAllPosition',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Tracking.TrackingAllPosition', active: true}
            ]
          }
        },

        {
          path: 'master/tracking/detail/:vesselId',
          name: 'master-tracking-detail',
          component: () => import ('./views/pages/tracking/DetailPosition'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Tracking.TrackingDetail',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Tracking.Main', url: '/master/tracking'},
              {title: 'Tracking.TrackingDetail', active: true}
            ]
          }
        },

        {
          path: 'master/tracking/find-vessel',
          name: 'master-tracking-find-vessel',
          component: () => import ('./views/pages/tracking/FindVessel'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Tracking.TrackingFind',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Tracking.TrackingFind', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master: state
        // ///////////////////////////////////////////////////////////
        {
          path: 'master/state',
          name: 'master-state',
          component: () => import ('./views/pages/state/State'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'State',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'State', active: true}
            ]
          }
        },
        {
          path: 'master/state/create',
          name: 'create-master-state',
          component: () => import ('./views/pages/state/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'State',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'State', url: '/master/state'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: 'master/state/detail',
          name: 'detail-master-state',
          component: () => import ('./views/pages/state/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'State',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'State', url: '/master/state'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: 'master/state/update',
          name: 'update-master-state',
          component: () => import ('./views/pages/state/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'State',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'State', url: '/master/state'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master: city
        // ///////////////////////////////////////////////////////////
        {
          path: 'master/city',
          name: 'master-city',
          component: () => import ('./views/pages/city/City'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'City',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'City', active: true}
            ]
          }
        },
        {
          path: 'master/city/create',
          name: 'create-master-city',
          component: () => import ('./views/pages/city/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'City',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'City', url: '/master/city'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: 'master/city/detail',
          name: 'detail-master-city',
          component: () => import ('./views/pages/city/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'City',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'City', url: '/master/city'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: 'master/city/update',
          name: 'update-master-city',
          component: () => import ('./views/pages/city/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'City',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'City', url: '/master/city'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : company
        // ///////////////////////////////////////////////////////////
        {
          path: 'master/company/:type',
          name: 'master-company',
          component: () => import ('./views/pages/company/Company'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Company', active: true}
            ]
          },
          children: [
            {
              path: ':tab',
              name: 'master-company-tab',
              meta: {
                rule: 'administrator',
                loginRequired: true,
                pageTitle: 'Company',
                breadcrumb: [
                  {title: 'Home', url: '/'},
                  {title: 'Master'},
                  {title: 'Company', active: true}
                ]
              }
            }
          ]
        },
        {
          path: 'master/company/detail/:type/:id',
          name: 'detail-master-company',
          component: () => import ('./views/pages/company/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: 'master/company/detail-validation/:type/:id',
          name: 'detail-validation-master-company',
          component: () => import ('./views/pages/company/DetailValidation'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Detail Validation', active: true}
            ]
          }
        },
        {
          path: 'master/company/create/:type',
          name: 'create-master-company',
          component: () => import ('./views/pages/company/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: 'master/company/update/:type/:id',
          name: 'update-master-company',
          component: () => import ('./views/pages/company/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Update', active: true}
            ]
          }
        },
        {
          path: 'master/company-advertisement',
          name: 'master-company-advertisement',
          component: () => import ('./views/pages/company/advertisement/Advertisement'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Company'},
              {title: 'Advertisement', active: true}
            ]
          }
        },
        {
          path: 'master/company-advertisement/create',
          name: 'create-master-company-advertisement',
          component: () => import ('./views/pages/company/advertisement/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Company', url : '/master/company-advertisement'},
              {title: 'Advertisement', active: true}
            ]
          }
        },
        {
          path: 'master/branch-company-advertisement/create/:id',
          name: 'create-master-branch-company-advertisement',
          component: () => import ('./views/pages/company/advertisement/CreateBranch'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Company', url : '/master/company-advertisement'},
              {title: 'Advertisement', active: true}
            ]
          }
        },
        {
          path: 'master/company-advertisement/update/:id',
          name: 'update-master-company-advertisement',
          component: () => import ('./views/pages/company/advertisement/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Company', url : '/master/company-advertisement'},
              {title: 'Advertisement', active: true}
            ]
          }
        },
        {
          path: 'master/branch-company-advertisement/update/:id',
          name: 'update-master-branch-company-advertisement',
          component: () => import ('./views/pages/company/advertisement/UpdateBranch'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Company', url : '/master/company-advertisement'},
              {title: 'Advertisement', active: true}
            ]
          }
        },
        {
          path: 'master/company-advertisement/:id',
          name: 'detail-company-advertisement',
          component: () => import ('./views/pages/company/advertisement/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Company', url : '/master/company-advertisement'},
              {title: 'Advertisement', active: true}
            ]
          }
        },
        {
          path: 'master/branch-company-advertisement/:id',
          name: 'detail-master-company-branch-advertisement',
          component: () => import ('./views/pages/company/advertisement/DetailBranch'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Company',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Company', url : '/master/company-advertisement'},
              {title: 'Advertisement', active: true}
            ]
          }
        },

          // ///////////////////////////////////////////////////////////
        // master : company
        // ///////////////////////////////////////////////////////////
        {
          path: 'master/conship',
          name: 'master-conship',
          component: () => import ('./views/pages/conship/Conship'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Consignee & Shipper',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Consinee & Shipper', active: true}
            ]
          }
        },
        {
          path: 'master/conship/detail',
          name: 'detail-master-conship',
          component: () => import ('./views/pages/conship/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Consignee & Shipper',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Consinee & Shipper', url: '/master/conship'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: 'master/conship/create',
          name: 'create-master-conship',
          component: () => import ('./views/pages/conship/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Consignee & Shipper',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Consinee & Shipper', url: '/master/conship'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: 'master/conship/update',
          name: 'update-master-conship',
          component: () => import ('./views/pages/conship/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Consignee & Shipper',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Consinee & Shipper', url: '/master/conship'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : port
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/port',
          name: 'master-port',
          component: () => import ('./views/pages/port/Port'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Port',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Port', active: true}
            ]
          }
        },
        {
          path: '/master/port/detail',
          name: 'detail-master-port',
          component: () => import ('./views/pages/port/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Port',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Port', url: '/master/port'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/port/create',
          name: 'create-master-port',
          component: () => import ('./views/pages/port/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Port',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Port', url: '/master/port'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/port/update',
          name: 'update-master-port',
          component: () => import ('./views/pages/port/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Port',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Port', url: '/master/port'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : cargo type
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/cargo-type',
          name: 'master-cargo_type',
          component: () => import ('./views/pages/cargo/CargoType'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'CargoType',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Cargo Type', active: true}
            ]
          }
        },
        {
          path: '/master/cargo-type/create',
          name: 'create-master-cargo_type',
          component: () => import ('./views/pages/cargo/CreateCargoType'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'CargoType',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Cargo Type', url: '/master/cargo-type'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/cargo-type/detail',
          name: 'detail-master-cargo_type',
          component: () => import ('./views/pages/cargo/DetailCargoType'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'CargoType',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Cargo Type', url: '/master/cargo-type'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/cargo-type/update',
          name: 'update-master-cargo_type',
          component: () => import ('./views/pages/cargo/UpdateCargoType'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'CargoType',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Cargo Type', url: '/master/cargo-type'},
              {title: 'Detail', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : ads category
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/ads-category',
          name: 'master-ads-category',
          component: () => import ('./views/pages/ads-category/AdsCategory'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsCategory',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsCategory', active: true}
            ]
          }
        },
        {
          path: '/master/ads-category/create',
          name: 'create-master-ads-category',
          component: () => import ('./views/pages/ads-category/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsCategory',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsCategory', url: '/master/ads-category'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/ads-category/detail/:id',
          name: 'detail-master-ads-category',
          component: () => import ('./views/pages/ads-category/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsCategory',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsCategory', url: '/master/ads-category'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/ads-category/update/:id',
          name: 'update-master-ads-category',
          component: () => import ('./views/pages/ads-category/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsCategory',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsCategory', url: '/master/ads-category'},
              {title: 'Detail', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : ads product
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/ads-product',
          name: 'master-ads-product',
          component: () => import ('./views/pages/ads-product/AdsProduct'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsProduct',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsProduct', active: true}
            ]
          }
        },
        {
          path: '/master/ads-product/create',
          name: 'create-master-ads-product',
          component: () => import ('./views/pages/ads-product/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsProduct',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsProduct', url: '/master/ads-product'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/ads-product/detail/:id',
          name: 'detail-master-ads-product',
          component: () => import ('./views/pages/ads-product/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsProduct',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsProduct', url: '/master/ads-product'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/ads-product/detail/:adsCompany/:adsCompanyBranch/statistic',
          name: 'detail-master-ads-product-statistic',
          component: () => import ('./views/pages/ads-product/Statistic'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsProduct',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsProduct', url: '/master/ads-product'},
              {title: 'Statistic', active: true}
            ]
          }
        },
        {
          path: '/master/ads-product/detail/:adsCompany/:adsCompanyBranch/statistic/:pkey/daily',
          name: 'detail-master-ads-product-statistic-daily',
          component: () => import ('./views/pages/ads-product/DailyStatistic'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsProduct',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsProduct', url: '/master/ads-product'},
              {title: 'Daily Statistic', active: true}
            ]
          }
        },
        {
          path: '/master/ads-product/detail/:adsCompany/:adsCompanyBranch/statistic/:pkey/click',
          name: 'detail-master-ads-product-statistic-click',
          component: () => import ('./views/pages/ads-product/ClickStatistic'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsProduct',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsProduct', url: '/master/ads-product'},
              {title: 'Click Statistic', active: true}
            ]
          }
        },
        {
          path: '/master/ads-product/product/detail/:id',
          name: 'detail-product-master-ads-product',
          component: () => import ('./views/pages/ads-product/DetailProduct'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsProduct',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsProduct', url: '/master/ads-product'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/ads-product/update/:id',
          name: 'update-master-ads-product',
          component: () => import ('./views/pages/ads-product/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsProduct',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsProduct', url: '/master/ads-product'},
              {title: 'Detail', active: true}
            ]
          }
        },

        {
          path: '/master/ads-product/license/:id',
          name: 'master-ads-product-license',
          component: () => import ('./views/pages/ads-product/license/License'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsProductLicense',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsProduct', url: '/master/ads-product' },
              {title: 'License', active: true}
            ]
          }
        },
        {
          path: '/master/ads-product/license/detail/:id',
          name: 'detail-master-ads-product-license',
          component: () => import ('./views/pages/ads-product/license/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'AdsProductLicense',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'AdsProduct', url: '/master/ads-product'},
              {title: 'License'},
              {title: 'Detail', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : vessel
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/vessel',
          name: 'master-vessel',
          component: () => import ('./views/pages/vessel/Vessel'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Vessel',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Vessel', active: true}
            ]
          }
        },
        {
          path: '/master/vessel/detail/:id',
          name: 'detail-master-vessel',
          component: () => import ('./views/pages/vessel/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Vessel',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Vessel', url: '/master/vessel'},
              {title: 'Detail', active: true}

            ]
          }
        },
        {
          path: '/master/vessel/create',
          name: 'create-master-vessel',
          component: () => import ('./views/pages/vessel/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'CreateVessel',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Vessel', url: '/master/vessel'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/vessel/update/:id',
          name: 'update-master-vessel',
          component: () => import ('./views/pages/vessel/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'UpdateVessel',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Vessel', url: '/master/vessel'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : verify vessel
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/vessel/verify',
          name: 'verify-master-vessel',
          component: () => import ('./views/pages/vessel/verify/Verify'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'VerifyVessel',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Vessel', url: '/master/vessel'},
              {title: 'Verify', active: true}
            ]
          }
        },
        {
          path: '/master/vessel/:type/detail/:id',
          name: 'detail-verify-master-vessel',
          component: () => import ('./views/pages/vessel/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'VerifyVessel',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Vessel', url: '/master/vessel'},
              {title: 'Verify', url: '/master/vessel/verify'},
              {title: 'Detail', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : licenses vessel
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/vessel-license',
          name: 'master-vessel-license',
          component: () => import ('./views/pages/vessel/license/License'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'LicenseVessel',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'License Vessel', active: true}
            ]
          }
        },
        {
          path: '/master/vessel-license/detail/:id',
          name: 'detail-master-vessel-license',
          component: () => import ('./views/pages/vessel/license/DetailLicense'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'LicenseVessel',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'License Vessel', url: '/master/vessel-license'},
              {title: 'Detail', active: true}
            ]
          }
        },
        // {
        //   path: '/master/vessel/license/update/:id',
        //   name: 'update-master-license-vessel',
        //   component: () => import ('./views/pages/vessel/license/UpdateLicense'),
        //   meta: {
        //     rule: 'administrator',
        //     loginRequired: true,
        //     pageTitle: 'License Vessel',
        //     breadcrumb: [
        //       {title: 'Home', url: '/'},
        //       {title: 'Master'},
        //       {title: 'License Vessel', url: '/master/vessel/license'},
        //       {title: 'Update', active: true}
        //     ]
        //   }
        // },

        // ///////////////////////////////////////////////////////////
        // master : vessel type
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/vessel/type',
          name: 'master-vessel_type',
          component: () => import ('./views/pages/vessel_type/VesselType'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'VesselType',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'VesselType', active: true}
            ]
          }
        },
        {
          path: '/master/vessel/type/create',
          name: 'create-master-vessel_type',
          component: () => import ('./views/pages/vessel_type/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'VesselType',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'VesselType', url: '/master/vessel/type'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/vessel/type/detail/:id',
          name: 'detail-master-vessel_type',
          component: () => import ('./views/pages/vessel_type/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'VesselType',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'VesselType', url: '/master/vessel/type'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/vessel/type/update/:id',
          name: 'update-master-vessel_type',
          component: () => import ('./views/pages/vessel_type/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'VesselType',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'VesselType', url: '/master/vessel/type'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : vessel checkup
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/vessel-checkup',
          name: 'master-vessel-checkup',
          component: () => import ('./views/pages/vessel/checkup/Checkup'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Vessel Checkup',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'EngineHealth', active: true},
              {title: 'CheckUpRequest', active: true}
            ]
          }
        },
        {
          path: '/master/vessel-checkup/:id',
          name: 'detail-master-vessel-checkup',
          component: () => import ('./views/pages/vessel/checkup/DetailCheckup'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Vessel Checkup',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'EngineHealth', active: true},
              {title: 'CheckUpRequest', url: '/master/vessel-checkup'},
              {title: 'Detail', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : vessel engine
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/vessel-engine/:vesselId',
          name: 'master-vessel-engine',
          component: () => import ('./views/pages/vessel/engine/Engine'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Vessel Engine',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Vessel', url: '/master/vessel'},
              {title: 'Engine', active: true}
            ]
          }
        },
        {
          path: '/master/vessel-engine/create/:vesselId',
          name: 'master-vessel-engine-create',
          component: () => import ('./views/pages/vessel/engine/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Vessel Engine',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Vessel', url: '/master/vessel'},
              {title: 'Engine', active: true},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/vessel-engine/update/:vesselId/:vesselEngineId',
          name: 'master-vessel-engine-update',
          component: () => import ('./views/pages/vessel/engine/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Vessel Engine',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Vessel', url: '/master/vessel'},
              {title: 'Engine', active: true},
              {title: 'Update', active: true}
            ]
          }
        },
        {
          path: '/master/vessel-engine/detail/:id',
          name: 'detail-master-vessel-engine',
          component: () => import ('./views/pages/vessel/engine/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Vessel Engine',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Vessel', url: '/master/vessel'},
              {title: 'Engine', active: true},
              {title: 'Detail', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : vessel engine checkup report
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/vessel-engine-report/:vesselEngineCheckupId/:vesselEngineId',
          name: 'master-vessel-engine-report',
          component: () => import ('./views/pages/vessel/checkup/Report'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Vessel Engine Checkup',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Vessel', url: '/master/vessel'},
              {title: 'Check Up Request', url: '/master/vessel-checkup'},
              {title: 'Report', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : user
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/user',
          name: 'master-user',
          component: () => import ('./views/pages/user/User'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'User',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'User', active: true}
            ]
          }
        },
        {
          path: '/master/user/verify',
          name: 'master-user-verify',
          component: () => import ('./views/pages/user/Verify'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'User',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'User Verify', active: true}
            ]
          }
        },
        {
          path: '/master/user/create',
          name: 'create-master-user',
          component: () => import ('./views/pages/user/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'User',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'User', url: '/master/user'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/user/detail/:id',
          name: 'detail-master-user',
          component: () => import ('./views/pages/user/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'User',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'User', url: '/master/user'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/user/verify-detail/:id',
          name: 'detail-master-user-verify',
          component: () => import ('./views/pages/user/DetailVerify'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'User',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'User Verify', url: '/master/user/verify'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/user/update/:id',
          name: 'update-master-user',
          component: () => import ('./views/pages/user/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'User',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'User', url: '/master/user'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : role
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/roles',
          name: 'master-roles',
          component: () => import ('./views/pages/roles/Role'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Role',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Role', active: true}
            ]
          }
        },
        {
          path: '/master/roles/create',
          name: 'create-master-roles',
          component: () => import ('./views/pages/roles/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Role',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Role', url: '/master/roles'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/roles/detail',
          name: 'detail-master-roles',
          component: () => import ('./views/pages/roles/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Role',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Role', url: '/master/roles'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/roles/update',
          name: 'update-master-roles',
          component: () => import ('./views/pages/roles/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Role',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Role', url: '/master/roles'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : email template
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/email-template',
          name: 'master-email-template',
          component: () => import ('./views/pages/email-template/EmailTemplate'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Email Template',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Email Template', active: true}
            ]
          }
        },
        {
          path: '/master/email-template/create',
          name: 'create-master-email-template',
          component: () => import ('./views/pages/email-template/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Email Template',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Email Template', url: '/master/email-template'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/email-template/detail',
          name: 'detail-master-email-template',
          component: () => import ('./views/pages/email-template/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Email Template',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Email Template', url: '/master/email-template'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/email-template/update',
          name: 'update-master-email-template',
          component: () => import ('./views/pages/email-template/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Email Template',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Email Template', url: '/master/email-template'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : offer status type
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/offer-status-type',
          name: 'master-offer-status-type',
          component: () => import ('./views/pages/offer-status-type/OfferStatusType'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Offer Status Type',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Offer Status Type', active: true}
            ]
          }
        },
        {
          path: '/master/offer-status-type/create',
          name: 'create-master-offer-status-type',
          component: () => import ('./views/pages/offer-status-type/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Offer Status Type',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Offer Status Type', url: '/master/offer-status-type'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/offer-status-type/detail',
          name: 'detail-master-offer-status-type',
          component: () => import ('./views/pages/offer-status-type/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Offer Status Type',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Offer Status Type', url: '/master/offer-status-type'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/offer-status-type/update',
          name: 'update-master-offer-status-type',
          component: () => import ('./views/pages/offer-status-type/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Offer Status Type',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Offer Status Type', url: '/master/offer-status-type'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : api keys
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/api-keys',
          name: 'master-api-keys',
          component: () => import ('./views/pages/api-key/ApiKey'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Api Keys',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Api Keys', active: true}
            ]
          }
        },
        {
          path: '/master/api-keys/detail',
          name: 'detail-master-api-keys',
          component: () => import ('./views/pages/api-key/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Api Keys',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Offer Status Type', url: '/master/api-keys'},
              {title: 'Detail', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : settings
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/settings',
          name: 'master-settings',
          component: () => import ('./views/pages/settings/Settings'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Settings',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Settings', active: true}
            ]
          }
        },
        {
          path: '/master/settings/create',
          name: 'create-master-settings',
          component: () => import ('./views/pages/settings/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Settings',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Settings', url: '/master/settings'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/settings/detail',
          name: 'detail-master-settings',
          component: () => import ('./views/pages/settings/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Settings',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Settings', url: '/master/settings'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/settings/update',
          name: 'update-master-settings',
          component: () => import ('./views/pages/settings/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Settings',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Settings', url: '/master/settings'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // master : classification
        // ///////////////////////////////////////////////////////////
        {
          path: '/master/classification',
          name: 'master-classification',
          component: () => import ('./views/pages/classification/Classification'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Classification',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Classification', active: true}
            ]
          }
        },
        {
          path: '/master/classification/create',
          name: 'create-master-classification',
          component: () => import ('./views/pages/classification/Create'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Classification',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Classification', url: '/master/classification'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/master/classification/detail',
          name: 'detail-master-classification',
          component: () => import ('./views/pages/classification/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Classification',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Classification', url: '/master/classification'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/master/classification/update',
          name: 'update-master-classification',
          component: () => import ('./views/pages/classification/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Classification',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Master'},
              {title: 'Classification', url: '/master/classification'},
              {title: 'Update', active: true}
            ]
          }
        },


        // ///////////////////////////////////////////////////////////
        // transaction : shipments
        // ///////////////////////////////////////////////////////////
        {
          path: '/transaction/shipments',
          name: 'transaction-shipments',
          component: () => import ('./views/pages/shipments/Shipments'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Shipments',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Transaction'},
              {title: 'Shipments', active: true}
            ]
          }
        },
        {
          path: '/transaction/shipment/detail/:id',
          name: 'detail-transaction-shipment',
          component: () => import ('./views/pages/shipments/Detail'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Shipments',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Transaction'},
              {title: 'Shipments', url: '/transaction/shipments'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/transaction/shipment/update',
          name: 'update-transaction-shipment',
          component: () => import ('./views/pages/shipments/Update'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Shipments',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Transaction'},
              {title: 'Shipments', url: '/transaction/shipments'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // transaction : payment-license
        // ///////////////////////////////////////////////////////////
        {
          path: '/transaction/payment-license/vessel',
          name: 'transaction-vessel-payment-license',
          component: () => import ('./views/pages/payment-license/vessel/PaymentLicense'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'PaymentLicense',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Transaction'},
              {title: 'PaymentLicense', active: true}
            ]
          }
        },
        {
          path: '/transaction/payment-license/vessel/detail/:id',
          name: 'transaction-detail-vessel-payment-license',
          component: () => import ('./views/pages/payment-license/vessel/DetailPaymentLicense'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'PaymentLicense',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Transaction'},
              {title: 'PaymentLicense', url: '/transaction/payment-license/vessel'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/transaction/payment-license/ads-product',
          name: 'transaction-ads-product-payment-license',
          component: () => import ('./views/pages/payment-license/ads-product/PaymentLicense'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'PaymentLicense',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Transaction'},
              {title: 'PaymentLicense', active: true}
            ]
          }
        },
        {
          path: '/transaction/payment-license/ads-product/detail/:id',
          name: 'transaction-detail-ads-product-payment-license',
          component: () => import ('./views/pages/payment-license/ads-product/DetailPaymentLicense'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'PaymentLicense',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Transaction'},
              {title: 'PaymentLicense', url: '/transaction/payment-license/ads-product'},
              {title: 'Detail', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // setting : custom field
        // ///////////////////////////////////////////////////////////
        {
          path: '/setting/custom-field',
          name: 'custom-field',
          component: () => import ('./views/pages/custom-field/CustomField.vue'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'CustomField',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Setting'},
              {title: 'Custom Field', active: true}
            ]
          }
        },
        {
          path: '/setting/custom-field/detail/:id',
          name: 'detail-custom-field',
          component: () => import ('./views/pages/custom-field/Detail.vue'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'CustomField',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Setting'},
              {title: 'Custom Field', url: '/setting/custom-field'},
              {title: 'Detail', active: true}
            ]
          }
        },
        {
          path: '/setting/custom-field/create',
          name: 'create-custom-field',
          component: () => import ('./views/pages/custom-field/Create.vue'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'CustomField',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Setting'},
              {title: 'Custom Field', url: '/setting/custom-field'},
              {title: 'Create', active: true}
            ]
          }
        },
        {
          path: '/setting/custom-field/update/:id',
          name: 'update-custom-field',
          component: () => import ('./views/pages/custom-field/Update.vue'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'CustomField',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Setting'},
              {title: 'Custom Field', url: '/setting/custom-field'},
              {title: 'Update', active: true}
            ]
          }
        },

        // ///////////////////////////////////////////////////////////
        // account: Notification
        // ///////////////////////////////////////////////////////////
        {
          path: '/account/notification',
          name: 'notification',
          component: () => import ('./views/pages/notification/Notification.vue'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Notification',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Account'},
              {title: 'Notification', active: true}
            ]
          }
        },

         // ///////////////////////////////////////////////////////////
        // account: Profile
        // ///////////////////////////////////////////////////////////
        {
          path: '/account/profile',
          name: 'profile',
          component: () => import ('./views/pages/profiles/Profile.vue'),
          meta: {
            rule: 'administrator',
            loginRequired: true,
            pageTitle: 'Profile',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Account'},
              {title: 'Profile', active: true}
            ]
          }
        }
      ]
    },

    // ///////////////////////////////////////////////////////////
    // master : other page
    // ///////////////////////////////////////////////////////////
    {
      path: '',
      component: () => import('./layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/',
          redirect: '/auth/login',
          meta: {
            rule: 'all',
            guest: true
          }
        },
        {
          path: '/error404',
          name: 'error-404-page',
          component: () => import('./views/pages/Error404'),
          meta: {
            rule: 'editor',
            guest: true
          }
        },
        {
          path: '/error401',
          name: 'error-401-page',
          component: () => import('./views/pages/Error404'),
          meta: {
            rule: 'all',
            guest: true
          }
        },
        {
          path: '/auth/login',
          name: 'login-page',
          component: () => import('./views/auth/Login'),
          meta: {
            rule: 'all',
            guest: true
          }
        },
        {
          path: '/auth/forgot-password',
          name: 'forgot-password',
          component: () => import('./views/auth/ForgotPassword'),
          meta: {
            rule: 'all',
            guest: true
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/error404'
    }
  ]
})

router.afterEach (() => {
  const appLoading = document.getElementById ('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach ((to, from, next) => {
  // if (to.meta.loginRequired) {
  //   store.dispatch('moduleAuth/sessioncek')
  //   if (!store.state.moduleAuth.loginStatus ()) {
  //     document.location = '/auth/login'
  //     return
  //   }
  // }
  // return next ()
  if (to.matched.some(record => record.meta.loginRequired)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem('bearerToken') && !store.getters.isLoggedIn) {
      next({ name: 'login-page' })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters.isLoggedIn && localStorage.getItem ('bearerToken')) {
      next({ name: 'dashboard' })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
