
module.exports = {
  // menu navigasi
  Master: 'Master',
  Transaction: 'Transaksi',
  Menu: {
    MainMenu: 'Menu Utama',
    MasterMenu: 'Menu Master',
    TransactionMenu: 'Menu Transaksi',
    ReportingMenu: 'Menu Laporan',
    SettingMenu: 'Menu Pengaturan',
    OtherMenu: 'Menu Lainnya'
  },
  History: 'Riwayat',
  Welcome: {
    LoginPage: 'Selamat datang kembali, silakan masuk ke akun Anda.'
  },
  Message: {
    loginRequired: 'Session tidak valid. Silakan login kembali untuk mengakses halaman tersebut.',
    invalidData: 'Data tersebut tidak valid.',
    areYouSure: 'Apakah Anda yakin ?',
    deleteConfirmation: 'Data yang sudah dihapus tidak dapat dikembalikan, apakah Anda ingin melakukannya?',
    acceptConfirmation: 'Data yang sudah disetujui tidak dapat dirubah, apakah Anda ingin melakukannya?',
    rejectConfirmation: 'Data yang sudah ditolak tidak dapat dirubah, apakah Anda ingin melakukannya?',
    noData: '-Tidak ada data-',
    noDocument: 'Tidak ada document yang diupload oleh pemilik kapal ini.',
    clickOnDocument: 'Click pada document untuk mengunduh atau membuka file di bawah ini.',
    system: '-SYSTEM-',
    chooseOne: '-Pilih salah satu-',
    registeredVessel: 'Berikut adalah daftar kapal yang sudah teregistrasi pada sistem.',
    registeredVessel_valid: 'Berikut adalah daftar kapal yang sudah teregistrasi pada sistem dan sudah dilakukan validasi.'
  },
  Validation: {
    currencyRequired: 'Mata uang wajib diisi',
    validate: 'Validasi'
  },
  Pages: {
    MasterCountry: 'Master data Negara'
  },
  Information: {
    defaultPassword: 'Password akan di generate secara default oleh sistem dan akan dikirimkan ke alamat email yang tertera.'
  },
  Status: {
    New: 'Baru',
    Rejected: 'Ditolak',
    Verified: 'Diverifikasi',
    Pending: 'Ditunda',
    NotActive: 'Tidak Aktif',
    Active: 'Aktif'
  },
  PageTitle: 'Berikut adalah data {msg} yang terdaftar pada sistem ini.',
  Password: 'Kata Sandi',
  Confirm: 'Konfirmasi',
  Cancel: 'Batal',
  Login: 'Masuk',
  ForgotPassword: 'Lupa Kata Sandi',
  Dashboard: 'Dashboard',
  Email: 'Email',
  Country: 'Negara',
  Id: 'Id',
  Name: 'Nama',
  Iso2: 'ISO-2',
  Iso3: 'ISO-3',
  State: 'Provinsi',
  City: 'Kota',
  Address: 'Alamat',
  Module: 'Modul',
  QtyType: 'Tipe kuantitas',
  Qty: 'Kuantitas',
  Company: 'Perusahaan',
  CompanyParent: 'Perusahaan Induk',
  Cargo: 'Kargo',
  CargoType: 'Tipe Kargo',
  CargoName: 'Nama Kargo',
  CargoGroup: 'Kelompok Kargo',
  CargoOwner: 'Pemilik Kargo',
  Ship: 'Kapal',
  Port: 'Pelabuhan',
  Roles: 'Role',
  UserModule: 'Module User',
  User: 'Pengguna',
  Users: 'Pengguna',
  VesselType: 'Tipe Vessel',
  Classification: 'Klasifikasi',
  FuelType: 'Tipe Bahan Bakar',
  Vessel: 'Kapal',
  VesselDocument: 'Dokumen Kapal',
  VesselName: 'Nama Kapal',
  VerifyVessel: 'Validasi Kapal',
  LicenseVessel: 'Lisensi Kapal',
  EngineHealth: 'Kesehatan Mesin',
  CheckUpRequest: 'Status Pengajuan',
  License: 'Lisensi',
  Payments: 'Pembayaran',
  PaymentLicense: 'Pembayaran Lisensi',
  VerifyUser: 'Validasi Pengguna',
  Shipments: 'Pengiriman',
  Offers: 'Total Penawaran',
  Offering: 'Penawaran',
  Setting: 'Pengaturan',
  Logout: 'Keluar',
  Create: 'Buat',
  Update: 'Perbarui',
  Detail: 'Detail',
  PhoneCode: 'Kode telepon',
  Currency: 'Mata Uang',
  Summary: 'Ringkasan',
  InvalidData: 'Data tidak valid',
  Success: 'Berhasil!',
  Verified: 'Terverifikasi',
  NotVerified: 'Tidak terverifikasi',
  ShipOwner: 'Pemilik Kapal',
  Yes: 'Ya',
  No: 'Tidak',
  Consignee: 'Consignee',
  Shipper: 'Shipper',
  Phone: 'Telepon',
  RequireTugboat: 'Membutuhkan Tugboat',
  CallSign: 'Tanda Panggilan',
  IMONumber: 'Nomor IMO',
  ShipyardName: 'Nama Galangan Kapal',
  ClassificationDate: 'Tanggal Klasifikasi',
  BuildYear: 'Tahun Pembuatan',
  LOA: 'Panjang Keseluruhan',
  LBP: 'Panjang antara tegak lurus',
  Width: 'Lebar',
  Depth: 'Kedalaman',
  SummerDraft: 'Draft Musim Panas',
  WinterDraft: 'Draft Musim Dingin',
  TropicalDraft: 'Draft Tropis',
  FreshwaterDraft: 'Draft Air Tawar',
  WeightGross: 'Bobot Kotor',
  WeightNet: 'Bobot Bersih',
  Deadweight: 'Bobot Mati',
  MainEngine: 'Mesin Utama',
  AuxEngine: 'Mesin Tambahan',
  EngineNumber: 'Nomor Mesin',
  Valid: 'Valid',
  DefaultUser: 'Pengguna default',
  AcceptRequest: 'Terima Permintaan',
  RejectRequest: 'Tolak Permintaan',
  UpdateRequest: 'Update Permintaan',
  Type: 'Tipe',
  Solid: 'Padat',
  Liquid: 'Cair',
  VerifyCompany: 'Verifikasi Perusahaan',
  UpdateVessel: 'Update kapal',
  CreateVessel: 'Tambah kapal',
  VesselCount: 'Jumlah Kapal',
  PhoneNumber:'Nomor Telepon',
  Role:'Role',
  Username:'Nama Pengguna',
  CustomField: 'Pengaturan Field',
  LoadCapacity: 'Kapasitas Beban',
  Owner: 'Pemilik',
  Rejected: 'Ditolak',
  Deleted: 'Dihapus',
  Advertisement: 'Iklan',
  Branch: 'Cabang',
  AdsCategory: 'Kategori Iklan',
  AdsProduct: 'Produk Iklan',
  AdsProductLicense: 'Lisensi Produk Iklan',
  Report: 'Laporan',
  Log: {
    Main: 'Log',
    Visitor: 'Log Pengunjung',
    VisitorDetail: 'Detail Pengunjung',
    VisitorActivity: 'Aktifitas Pengunjung',
    VisitorActivityDetail: 'Detail Aktifitas Pengunjung'
  },
  Tracking: {
    Main: 'Tracking',
    TrackingAllPosition: 'Semua Posisi',
    TrackingFind: 'Cari Posisi Kapal',
    TrackingDetail: 'Detil Posisi Kapal'
  },
  EmailTemplate: 'Email Template',
  OfferStatusType: 'Tipe Status Penawaran',
  ApiKeys: 'List API Key',
  Settings: 'Pengaturan',
}
